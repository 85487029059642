import React, { useState } from "react";

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Button, Container, LogoIcon, NavItem, Dialog, Regulament, ContactForm } from '@design-system';

import { Main, Columns, Column, Nav, Ul, HeaderSvg } from './styles';
import { HeaderProps } from "./interfaces";


import { ModalStaticProps } from '@interfaces/dialog';
import { theme as t } from "@theme";

const Header: React.FC<HeaderProps> =
    ({
        logo,
        navData,
        backgroundColor,
        fontColor,
        activeMenuColorLink,
        ButtonCreateAccount,
        ButtonAccess,
        theme,
    }: HeaderProps) => {

        const { color } = t;

        const contactRef = React.useRef<ModalStaticProps>(null);
        const termsRef = React.useRef<ModalStaticProps>(null);

        const [navVisibility, setNavVisibility] = useState<boolean>(false);

        const handleMenu = () => {
            setNavVisibility((prev) => !prev);

            if (!navVisibility) {
                document.body.classList.add('overflow-h');
            } else {
                document.body.classList.remove('overflow-h');
            }
        }

        React.useEffect(() => {

            return () => {
                // removing classes from body element
                // when the component unmounts
                document.body.classList.remove('overflow-h');
            }
        }, []);

        React.useEffect(() => {
            $(`#sticker`).sticky({ topSpacing: 0, zIndex: '999' });

            $('#sticker').on('sticky-start', () => $("#sticker").addClass('sticker-active'));

            $('#sticker').on('sticky-end', () => $("#sticker").removeClass('sticker-active'));

        }, []);

        const handleDialog = (theme: string, key: string) => {

            if (theme === "campaign-theme") {

                switch (key) {

                    case "regulamento":
                        termsRef.current?.show()
                        break;

                    default:
                        (() => null)();
                        break;
                }
            }
            if (theme === "hotsite-theme") {

                switch (key) {
                    case "fale-conosco":
                        contactRef.current?.show()
                        break;

                    default:
                        (() => null)();
                        break;
                }
            }
        }

        return (
            <React.Fragment>

                <Main backgroundColor={backgroundColor} fontColor={fontColor} id="sticker">
                    <Container className="wrapper">

                        <Columns>
                            <Column className="header-control">
                                <LogoIcon src={logo} link={"/"} />
                                <HeaderSvg onClick={handleMenu}>
                                    <I icon={
                                        navVisibility
                                            ? ['far', 'times']
                                            : ['fas', 'bars']
                                    } size="lg" />
                                </HeaderSvg>
                            </Column>

                            <Column backgroundColor={backgroundColor} className={`second-column ${navVisibility ? 'menu-mobile' : ''}`}>
                                <Nav>
                                    <Ul>
                                        {navData.map(({ id, title, link, action }, i) => {

                                            const titleLower = title.replace(" ", "-").toLowerCase();

                                            return (
                                                <NavItem
                                                    key={`${title}-${i}`}
                                                    onClick={handleMenu}
                                                    colorLink={fontColor}
                                                    activeColorLink={activeMenuColorLink}
                                                    title={title}
                                                    link={link}
                                                    isActive={i + 1 === 1}
                                                    action={() => handleDialog(theme, titleLower)}
                                                />
                                            )
                                        })}
                                    </Ul>
                                </Nav>
                                <Button
                                    borderColor={ButtonCreateAccount.borderColor}
                                    withBorder={ButtonCreateAccount.withBorder}
                                    backgroundColor={ButtonCreateAccount.backgroundColor}
                                    fontColor={ButtonCreateAccount.fontColor}
                                    link={'#!'}
                                    text={ButtonCreateAccount.text}
                                    onClick={() => {
                                        window.open(ButtonCreateAccount.link, '_blank')
                                        handleMenu();
                                    }}
                                />
                                <Button
                                    borderColor={ButtonAccess.borderColor}
                                    withBorder={ButtonAccess.withBorder}
                                    backgroundColor={ButtonAccess.backgroundColor}
                                    fontColor={ButtonAccess.fontColor}
                                    link={ButtonAccess.link}
                                    text={ButtonAccess.text}
                                    onClick={() => {
                                        window.open(ButtonAccess.link, '_blank')
                                        handleMenu();
                                    }}
                                />
                            </Column>

                        </Columns>
                    </Container>
                </Main>

                <Dialog ref={contactRef}>
                    <ContactForm
                        backgroundColorButton={color.indigo[700]} fontColorButton={color.common.white}
                    />
                </Dialog>

                <Dialog ref={termsRef} title="Regulamento">
                    <Regulament />
                </Dialog>
            </React.Fragment>
        )
    }

export { Header };
