import React from "react";
import { LogoIcon, NavItem, Grid, Container } from '@design-system';
import LogoCommarket from '@img/logo-commarket.png';
import { Main,  Nav, Ul, Informative, CompanyInfo, ContainerStyled } from './styles';

const dataFooter = [
    {
        title: 'Termos de uso',
        link: '#termos-de-uso'
    },
    {
        title: 'Políticas de privacidade',
        link: '#politicas-privacidade'
    },
]

interface NavItemProps {
    title: string;
    link: string;
}

interface FooterProps {
    logo: string;
    backgroundColor: string;
    fontColor: string;
    navData: NavItemProps[];
}

const Footer: React.FC<FooterProps> =
  ({backgroundColor, fontColor, logo, navData}: FooterProps) => {
    return (
        <Main fontColor={fontColor} backgroundColor={backgroundColor}>
            <Container>
                <Grid container>
                    <Grid item lg={3} md={12}>
                        <LogoIcon src={logo} link={""} />
                    </Grid>
                    <Grid item lg={3} md={12}>
                        <Informative className="on-footer">
                            Consulte condições de participação, datas dos sorteios e regulamento completo aqui no site. Imagens ilustrativas.
                        </Informative>
                    </Grid>
                    <Grid item lg={3} md={12}>
                        <Nav>
                            <Ul>
                                {navData.map(({ title, link }, i) => (
                                    <NavItem
                                        key={`${title}-${i}`}
                                        colorLink={fontColor}
                                        activeColorLink="red"
                                        title={title}
                                        link={link}
                                        onFooter
                                        isActive={false}
                                    />
                                ))}
                            </Ul>
                        </Nav>
                    </Grid>
                    <Grid item lg={3} md={12}>
                        <Nav>
                            <Ul>
                                {dataFooter.map(({ title, link }, i) => (
                                    <NavItem
                                        key={`${title}-${i}`}
                                        colorLink={fontColor}
                                        activeColorLink="red"
                                        title={title}
                                        link={link}
                                        onFooter
                                        isActive={false}
                                    />
                                ))}
                            </Ul>
                        </Nav>
                    </Grid>
                </Grid>
            </Container>
            <CompanyInfo>
                <ContainerStyled>
                    <Informative>
                        <span>© 2022 Campanhas online</span>
                    </Informative>
                    <Informative>
                        <span>Desenvolvido por </span>
                        <img src={LogoCommarket} alt="commarket" />
                    </Informative>
                </ContainerStyled>
            </CompanyInfo>
        </Main>
    )
}

export { Footer };
