import React from 'react';

import { Main, Title, Count, ToggleIcon, Columns, Column, Content, Text } from './styles';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

interface AccordionSummaryProps {
    count: number;
    color: string;
    title: string;
    isActive: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const AccordionSummary: React.FC<AccordionSummaryProps> =
    ({ count, title, isActive, onClick, children, color }: AccordionSummaryProps) => {

        return (
            <Main type='button' onClick={onClick}>
                <Columns>
                    <Column>
                        <Count color={color}>
                            {count}
                        </Count>
                        <Title>{title}</Title>
                    </Column>
                    <Column>
                        <ToggleIcon>
                            <I icon={
                                !isActive
                                    ? ['fas', 'plus-circle']
                                    : ['fas', 'minus-circle']
                            } style={{ height: '1.625rem' }} color={color} />
                        </ToggleIcon>
                    </Column>
                </Columns>
                {isActive && (
                    <Content>
                        <Text>
                            {children}
                        </Text>
                    </Content>
                )}
            </Main>
        )
    }

export { AccordionSummary };