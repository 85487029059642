import BannerDefault from '@img/theme/hotsite/home/slider/banner.png';
import BannerDefaultMobile from '@img/theme/hotsite/home/slider/banner-mobile.png';
import HeaderLogo from '@img/theme/hotsite/logo@1x.png';

import Placeholder640x410 from '@img/placeholder-640x410.png';

import { theme } from '@theme';

const { color } = theme;

const nav = [
    {
        id: "1",
        title: 'Campanhas',
        link: '#campanhas'
    },
    {
        id: "2",
        title: 'Como funciona',
        link: '#como-funciona'
    },
    {
        id: "3",
        title: 'FAQ',
        link: '#duvidas-frequentes'
    },
    {
        id: "4",
        title: 'Fale Conosco',
        link: '#!',
    },
]

export const hotsiteTheme = {
    themeName: "hotsite-theme",
    header: {
        nav: [...nav],
        fontColor: color.grey[900],
        logo: HeaderLogo,
        activeMenuColorLink: color.purple[900],
        backgroundColor: color.common.white,
        ButtonCreateAccount: {
            withBorder: true,
            borderColor: color.purple[900],
            fontColor: color.purple[900],
            backgroundColor: 'transparent',
            text: 'Criar conta',
            link: 'https://css3generator.com/',
        },
        ButtonAccess: {
            withBorder: true,
            borderColor: color.purple[900],
            fontColor: color.purple[900],
            backgroundColor: 'transparent',
            text: 'Acesse',
            link: 'https://tokstok.d1.campanhasonline.com.br/portal/hotsite',
        }
    },
    footer: {
        logo: HeaderLogo,
        nav: [...nav],
        fontColor: color.grey[900],
        backgroundColor: color.grey[50],
    },
    colorHeadliners: color.deepPurple[900],
    sliderData: [
        {
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
    ],
    howWorksStyles: {
        backgroundColor: color.deepPurple[50],
        color: color.purple[900]
    },
    howWorksBoxData: [
        {
            title: 'Escolha a campanha',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            title: 'Se Inscreva',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            title: 'Tudo Pronto!',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
    ],
    campaignsSlider: [
        {
            image: Placeholder640x410,
            title: 'Campanha 1',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            link: '/campaign/1',
            colorButton: color.common.white,
            backgroundColorButton: color.deepPurple[900]
        },
        {
            image: Placeholder640x410,
            title: 'Campanha 2',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            link: '/campaign/2',
            colorButton: color.common.white,
            backgroundColorButton: color.deepPurple[900]
        },
        {
            image: Placeholder640x410,
            title: 'Campanha 3',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            link: '/campaign/3',
            colorButton: color.common.white,
            backgroundColorButton: color.deepPurple[900]
        },
        {
            image: Placeholder640x410,
            title: 'Campanha 4',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            link: '/campaign/4',
            colorButton: color.common.white,
            backgroundColorButton: color.deepPurple[900]
        },
        {
            image: Placeholder640x410,
            title: 'Campanha 5',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            link: '/campaign/5',
            colorButton: color.common.white,
            backgroundColorButton: color.deepPurple[900]
        },
        {
            image: Placeholder640x410,
            title: 'Campanha 6',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            link: '/campaign/6',
            colorButton: color.common.white,
            backgroundColorButton: color.deepPurple[900]
        },
    ],
    faq: {
        title: {
            text: "FAQ",
            color: color.grey[900]
        },
        accordion: {
            iconColor: color.purple[900]
        },
    },
    contact: {
        sectionContactColor: color.purple[900],
        backgroundColorButton: color.purple[900],
        fontColorButton: color.common.white,
    },
}