import React from "react";
import {Button} from '@design-system';

interface ButtonIconProps {
    link: string;
    text: string;
    withBorder: boolean;
    backgroundColor: string;
    borderColor: string;
    fontColor: string;
    iconSrc: React.ReactNode;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
  backgroundColor, borderColor, fontColor, iconSrc, link, text, withBorder
    }: ButtonIconProps) => {
    return (
        <Button
        link={link}
        text={text}
        withBorder={withBorder}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        fontColor={fontColor}>
            {iconSrc}
        </Button>
    )
}

export {ButtonIcon};