import React from 'react';

import { Main } from './styles';

interface HeadlineProps {
    color: string;
    children: React.ReactNode;
}

const Headline: React.FC<HeadlineProps> = ({ color, children }: HeadlineProps) => {
    return (
        <Main color={color}>
            {children}
        </Main>
    )
}

export { Headline };