import React from "react";
import {HowWorksBox} from '@design-system';
import {Main, Text, Title, Subtitle} from './styles';

interface HowWorksProps {
    backgroundColor: string;
    color: string;
    position: number;
    title: string;
    subtitle: string;
}

const HowWorks: React.FC<HowWorksProps> =
  ({backgroundColor, color, position, title, subtitle}: HowWorksProps) => {
    return (
        <Main>
            <HowWorksBox
              backgroundColor={backgroundColor}
              color={color}
              position={position}>
                <Text>
                    <Title color={color}>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </Text>
            </HowWorksBox>
        </Main>
    )
}

export {HowWorks};