import React from "react";

import {Main, HeaderBox} from './styles';

interface HowWorksBoxProps {
    backgroundColor: string;
    color: string;
    position: number;
    children: React.ReactNode;
}

const HowWorksBox: React.FC<HowWorksBoxProps> =
  ({backgroundColor, color, position, children}: HowWorksBoxProps) => {
    return (
        <Main borderColor={backgroundColor}>
            <HeaderBox backgroundColor={backgroundColor} color={color}>
                {position}
            </HeaderBox>
            {children}
        </Main>
    )
}

export {HowWorksBox};