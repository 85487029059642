import React, { HTMLProps } from "react";
import { Main } from './styles';

interface ButtonProps {
  link: string;
  text: string;
  withBorder: boolean;
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
  onClick?: any;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps & HTMLProps<HTMLButtonElement>> =
  (props: ButtonProps) => {

    const { link, text, withBorder, borderColor, fontColor, backgroundColor, onClick, children } = props;

    return (
      <Main
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        fontColor={fontColor}
        withBorder={withBorder}
        className={onClick ? "without-link" : ''}
        onClick={onClick}
      >

        {onClick
          ? [text, children]
          : <a href={link} target="_blank" rel="noreferrer">{children ? [text, children] : text}</a>
        }

      </Main>
    )
  }

export { Button };