import React from "react";
import { Link } from 'react-router-dom';
import { Image } from './styles';

interface LogoIconProps {
    src: string;
    link: string;
}

const LogoIcon: React.FC<LogoIconProps> = ({ src, link }: LogoIconProps) => {
    return (
        <Link to={link}>
            <Image src={src} alt="" />
        </Link>
    )
}

export { LogoIcon };