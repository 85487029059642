import React from 'react';
import { Routes as AppRoutes, Route, useLocation } from 'react-router-dom';
import {
    Hotsite,
    Campaign,
} from '@pages';

import { hotsiteTheme, campaignTheme } from '@data/theme';

import { useThemeContext } from '@hooks';

import { path } from './route-paths';
import { Header } from './components/patterns/header';

const Routes = () => {

    const { apply } = useThemeContext();
    const [theme, setTheme] = React.useState<any>();

    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);

        if (pathname.indexOf(path.campaign) !== -1) {
            setTheme(campaignTheme);
            apply(campaignTheme);
        } else {
            setTheme(hotsiteTheme);
            apply(hotsiteTheme);
        }
    }, [pathname, apply]);

    return (
        <React.Fragment>
            {theme
                ? (

                    <Header
                        theme={theme.themeName}
                        fontColor={theme.header.fontColor}
                        activeMenuColorLink={theme.header.activeMenuColorLink}
                        backgroundColor={theme.header.backgroundColor}
                        logo={theme.header.logo}
                        navData={theme.header.nav}
                        ButtonCreateAccount={theme.header.ButtonCreateAccount}
                        ButtonAccess={theme.header.ButtonAccess}
                    />
                )
                : ""
            }

            <AppRoutes>

                <Route index={true} element={<Hotsite theming={hotsiteTheme} />} />
                <Route>
                    <Route path={`${path.campaign}/:campaignPath`} element={<Campaign theming={campaignTheme} />} />
                </Route>

            </AppRoutes>
        </React.Fragment>
    )
}

export default Routes;