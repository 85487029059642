import styled from 'styled-components';

import { theme } from '@theme';

interface Props {
    backgroundColor: string;
    withBorder: boolean;
    borderColor: string;
    fontColor: string;
}

const { border } = theme;

export const Main = styled('button')`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props: Props) => props.fontColor};
    border-radius: ${border.default};
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    background-color: ${(props: Props) => props.backgroundColor};
    border: ${(props: Props) => props.withBorder === true ? 'solid 2px' : 'none'};
    border-color: ${(props: Props) => props.borderColor};
    white-space: nowrap;

    & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11.5px 30px;
        text-decoration: none;
        color: ${(props: Props) => props.fontColor};
        white-space: nowrap; 
        
        svg {
            margin-left: .625rem;
        }
    }
    
    &.without-link {
        padding: 11.5px 30px;
    }

`;
