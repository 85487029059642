import React from "react";

import {Main, P} from './styles';

const Regulament: React.FC = () => {
    return (
        <Main>
            <P>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusamus quaerat assumenda odio dolorem, eveniet voluptas dicta quam soluta adipisci. Nulla illo, facilis ut perferendis error autem perspiciatis quidem ea nesciunt!
            </P>
            <P>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deleniti, in neque? Perspiciatis sapiente sequi libero ea, rerum in, voluptatum consequatur nemo itaque debitis possimus, error quod neque nulla exercitationem quam!
            </P>
            <P>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse ipsam iusto tempore dolorem ratione eum natus temporibus deserunt quibusdam omnis dolore sed optio fuga dolores fugit aspernatur, est odio facilis.
            </P>
            <P>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia nihil odio totam ipsa consequuntur debitis maxime eaque error ex, repellendus quod ab sit non esse amet aliquam natus magni eius.
            </P>
            <P>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quas ratione, quod obcaecati ut, quisquam nihil inventore cumque sapiente eligendi eos officiis incidunt suscipit. Molestiae quod iste quibusdam nesciunt quo!
            </P>
            <P>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quas ratione, quod obcaecati ut, quisquam nihil inventore cumque sapiente eligendi eos officiis incidunt suscipit. Molestiae quod iste quibusdam nesciunt quo!
            </P>
            <P>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quas ratione, quod obcaecati ut, quisquam nihil inventore cumque sapiente eligendi eos officiis incidunt suscipit. Molestiae quod iste quibusdam nesciunt quo!
            </P>
        </Main>
    )
}

export {Regulament};