import styled from "styled-components";
import {theme} from '@theme';

interface TitleProps {
    color: string;
}

export const Main = styled.div`
`;

export const Text = styled.div`
    display: flex;
    height: 210px;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    padding-top: 44px;
`;

export const Title = styled.h2<TitleProps>`
    font-size: 20px;
    color: ${props => props.color};
`;

export const Subtitle = styled.p`
    text-align: center;
    font-size: 14px;
    padding-top: 14px;
    line-height: 2;
    color: ${theme.color.grey[700]}
`;