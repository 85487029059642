import styled from "styled-components";

import {theme} from '@theme';

interface Props {
    backgroundColor: string;
}

export const Main = styled.div`
    ${theme.breakpoints.down('md', `
        margin-bottom: 15px;
    `)}
`;

export const BoxContainer= styled.div<Props>`
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
    border-radius: ${theme.border.default};
    display: flex;
`;

export const BoxIcon = styled.div<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    background-color: ${props => props.backgroundColor};;
    border-radius: ${theme.border.default} 0 0 ${theme.border.default};
`;
