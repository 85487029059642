import React from 'react';
import { settings, settingsSlider } from './configs';
import { theme } from '@theme';
import {
    Container,
    Slider,
    Banner,
    Section,
    Headline,
    HowWorks,
    Card,
    ParticipateButton,
    Accordion,
    AccordionSummary
} from '@design-system';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { hotsiteTheme, faq } from '@data/theme';

import { Main, MainHowWorks, BoxContainer, ChevronDiv, MainSlider } from './styles';
import { Footer } from '@components';


interface HotsiteProps {
    theming: any;
}

const Hotsite: React.FC<HotsiteProps> = ({ theming }: HotsiteProps) => {

    const settingsBannersSlider = {
        ...settingsSlider,
        nextArrow: <button><I icon={['far', 'chevron-right']} size="3x" /></button>,
        prevArrow: <button><I icon={['far', 'chevron-left']} size="3x" /></button>,
    };

    const sliderSettings = {
        ...settings,
        nextArrow: <button><I icon={['far', 'chevron-right']} size="2x" color={theme.color.purple[900]} /></button>,
        prevArrow: <button><I icon={['far', 'chevron-left']} size="2x" color={theme.color.purple[900]} /></button>,
    }

    const [isOpen, setIsOpen] = React.useState<number>(0);
    const [isOpenChildren, setIsOpenChildren] = React.useState<number>(0);

    const toggleOpen = (id: number) => () => {
        setIsOpen((isOpen: number) => isOpen === id ? 0 : id);
        setIsOpenChildren(prev => prev = 0);
    };

    const toggleOpenChildren = (id: number) => () => setIsOpenChildren(
        (isOpenChildren: number) => isOpenChildren === id ? 0 : id,
    );

    return (
        <Main>

            <Slider className='main-slider' {...settingsBannersSlider}>
                {hotsiteTheme.sliderData.map(({ src, srcMobile, link }) => (
                    <Banner src={src} srcMobile={srcMobile} link={link} />
                ))}
            </Slider>

            <Section id='como-funciona'>
                <Container>
                    <Headline color={hotsiteTheme.colorHeadliners}>Como funciona</Headline>
                    <MainHowWorks>
                        {hotsiteTheme.howWorksBoxData.map(({ title, subtitle }, i) => {
                            const index = i + 1;
                            return (
                                <BoxContainer key={title + '-' + i}>
                                    <HowWorks
                                        backgroundColor={hotsiteTheme.howWorksStyles.backgroundColor}
                                        color={hotsiteTheme.howWorksStyles.color}
                                        position={index}
                                        title={title}
                                        subtitle={subtitle} />

                                    <ChevronDiv>
                                        <I
                                            icon={['fas', 'chevron-double-right']}
                                            style={{ height: '1.5rem' }}
                                            color={hotsiteTheme.howWorksStyles.color} />
                                    </ChevronDiv>
                                </BoxContainer>
                            )
                        })}
                    </MainHowWorks>
                </Container>
                <div id="campanhas" />
            </Section>

            <Section>
                <Container>
                    <Headline color={hotsiteTheme.colorHeadliners}>Campanhas ativas</Headline>
                    <MainSlider>
                        <Slider {...sliderSettings} className='campaign-slider'>
                            {hotsiteTheme.campaignsSlider.map(({ image, title, description, colorButton, link, backgroundColorButton }, i) => (
                                <Card
                                    key={`${i + 1}`}
                                    image={image}
                                    title={title}
                                    description={description}
                                >
                                    <ParticipateButton
                                        color={colorButton}
                                        link={link}
                                        backgroundColor={backgroundColorButton} />
                                </Card>
                            ))}
                        </Slider>
                    </MainSlider>
                </Container>
            </Section>

            <Section id="duvidas-frequentes">
                <Container>
                    <Headline color={hotsiteTheme.faq.title.color}>
                        {hotsiteTheme.faq.title.text}
                    </Headline>
                    {faq.map(({ id, title, questions }, i) => {
                        const idx = i + 1;
                        return (
                            <Accordion
                                key={`${id}`}
                                title={title}
                                color={hotsiteTheme.faq.accordion.iconColor}
                                onClick={toggleOpen(idx)}
                                isActive={isOpen === idx}
                            >
                                {questions.map(({ id, title, text }, i) => {
                                    const index = i + 1;
                                    return (
                                        <AccordionSummary
                                            key={`${id}`}
                                            count={index}
                                            title={title}
                                            color={hotsiteTheme.faq.accordion.iconColor}
                                            onClick={toggleOpenChildren(index)}
                                            isActive={isOpenChildren === index}>
                                            {text}
                                        </AccordionSummary>
                                    )
                                })}
                            </Accordion>
                        )
                    })}
                </Container>
            </Section>

            <Footer
                logo={theming.footer.logo}
                backgroundColor={theming.footer.backgroundColor}
                fontColor={theming.footer.fontColor}
                navData={theming.footer.nav}
            />
        </Main>
    );
}

export { Hotsite };