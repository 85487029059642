import BannerDefault from '@img/theme/campaign/home/slider/banner@2x.png';
import BannerDefaultMobile from '@img/theme/campaign/home/slider/banner-mob@2x.png';

import { theme } from '@theme';

import CapaignColumn1 from '@img/theme/campaign/home/campaign-column-1.png';
import CapaignColumn2 from '@img/theme/campaign/home/campaign-column-2.png';
import HeaderLogo from '@img/theme/campaign/logo@1x.png';
import Placeholder640x410 from '@img/placeholder-640x410.png';

const { color } = theme;

const nav = [
    {
        id: "1",
        title: 'Como participar',
        link: '#como-participar'
    },
    {
        id: "2",
        title: 'Regulamento',
        link: '#!'
    },
    {
        id: "3",
        title: 'FAQ',
        link: '#duvidas-frequentes'
    },
    {
        id: "4",
        title: 'Fale Conosco',
        link: '#fale-conosco'
    }
]

export const campaignTheme = {
    themeName: "campaign-theme",
    header: {
        nav: [...nav],
        fontColor: color.grey[900],
        logo: HeaderLogo,
        activeMenuColorLink: color.purple[900],
        backgroundColor: color.deepOrange[50],
        ButtonCreateAccount: {
            withBorder: true,
            borderColor: theme.color.purple[900],
            fontColor: theme.color.purple[900],
            backgroundColor: 'transparent',
            text: 'Criar conta',
            link: '',
        },
        ButtonAccess: {
            withBorder: true,
            borderColor: theme.color.purple[900],
            fontColor: theme.color.purple[900],
            backgroundColor: 'transparent',
            text: 'Acesse',
            link: '',
        }
    },
    footer: {
        logo: HeaderLogo,
        nav: [...nav],
        fontColor: theme.color.grey[900],
        backgroundColor: theme.color.grey[50],
    },
    colorHeadliners: color.deepPurple[900],
    sliderData: [
        {
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
    ],
    howWorksBoxStyles: {
        iconColor: theme.color.common.white,
        iconBackground: theme.color.deepPurple[900],
        backgroundColor: theme.color.grey[200],
        fontColor: theme.color.grey[900],
    },
    howWorksBoxData: [
        {
            iconData: ['fal', 'sign-in'],
            title: 'Cadastre-se',
            subtitle: 'Para acumular pontos basta estar cadastrado no programa.'
        },
        {
            iconData: ['far', 'braille'],
            title: 'Ganhe Pontos',
            subtitle: 'Ganhe 1 ponto a cada 1 real gasto em suas compras.'
        },
        {
            iconData: ['far', 'smile-beam'],
            title: 'Troque Seus Pontos',
            subtitle: 'Aproveite as ofertas turbinadas que fazemos ao longo do ano.'
        },
    ],
    boxSection1: {
        title: 'Ainda não é Cadastrado? ',
        text: 'Para acumular pontos basta estar cadastrado no programa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        image: CapaignColumn1,
        button: {
            link: '#!',
            text: 'Cadastre-se agora',
            withBorder: false,
            backgroundColor: color.amber[500],
            borderColor: color.amber[500],
            fontColor: color.grey[900],
            colorIcon: color.grey[900],
        }
    },
    boxSection2: {
        title: 'Já é Cadastrado? ',
        text: 'Para acumular pontos basta estar cadastrado no programa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        image: CapaignColumn2,
        button: {
            link: '#!',
            text: 'Ir para o portal',
            withBorder: false,
            backgroundColor: color.deepOrange[50],
            borderColor: color.deepOrange[50],
            fontColor: color.purple[900],
            colorIcon: color.purple[900],
        }
    },
    sectionPartnersColor: color.grey[50],
    genericSlider: [
        {
            image: Placeholder640x410,
            title: 'Campanha 1',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
            image: Placeholder640x410,
            title: 'Parceiro 2',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
            image: Placeholder640x410,
            title: 'Parceiro 3',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
            image: Placeholder640x410,
            title: 'Parceiro 4',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
            image: Placeholder640x410,
            title: 'Parceiro 5',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
            image: Placeholder640x410,
            title: 'Parceiro 6',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        },
    ],
    faq: {
        title: {
            text: "FAQ",
            color: color.purple[900]
        },
        accordion: {
            iconColor: color.deepOrange.A400
        },
    },
    contact: {
        sectionContactColor: color.purple[900],
        backgroundColorButton: color.purple[900],
        fontColorButton: color.common.white,
    },
}