import { theme } from '@theme';
import styled from 'styled-components';

interface NavLinkProps {
    colorLink: string;
    activeColorLink: string;
}

const { border, typography } = theme;

export const Main = styled('li')`
    padding: 10px 20px;

    &.footer {
        font-weight: 500;
        padding: 5px 0;
    }

`;

export const NavLink = styled.span<NavLinkProps>`
    color: ${({colorLink}) => colorLink};
    font-size: 16px;
    
    &.active {
        font-weight: ${typography.fontWeightBold};
        color: ${({activeColorLink}) => activeColorLink};
        &::after {
            content: '';
            display: block;
            height: .1875rem;
            width: 100%;
            background-color: ${({activeColorLink}) => activeColorLink};;
            position: relative;
            top: .125rem;
            border-radius: ${border.round};
        }
    }

    &.footer{
        font-size: 14px;
    }
`;