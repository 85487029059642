import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Main, Head, Title, ToggleIcon, Columns, Column } from './styles';


interface AccordionProps {
    title: string;
    isActive: boolean;
    color: string;
    onClick: () => void;
    children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, isActive, onClick, color, children }: AccordionProps) => {

    return (
        <Main>
            <Head onClick={onClick}>
                <Columns>
                    <Column>
                        <Title>{title}</Title>
                    </Column>
                    <Column>
                        <ToggleIcon>
                            <I icon={
                                !isActive
                                    ? ['fas', 'plus-circle']
                                    : ['fas', 'minus-circle']
                            } style={{ height: '1.625rem' }} color={color} />
                        </ToggleIcon>
                    </Column>
                </Columns>
            </Head>
            {isActive && (children)}
        </Main>
    )
}

export { Accordion };