import React from "react";
import {HowWorksBoxCampaign} from '@design-system';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import {Main, Title, Subtitle} from './styles';

interface HowWorksCampaignProps {
    iconData: IconProp;
    iconColor: string;
    iconBackground: string;
    backgroundColor: string;
    fontColor: string;
    title: string;
    subtitle: string;
}

const HowWorksCampaign =
  ({iconData, iconBackground, iconColor, backgroundColor, fontColor, title, subtitle}: HowWorksCampaignProps) => {
    return (
        <HowWorksBoxCampaign
          iconData={iconData}
          iconColor={iconColor}
          iconBackground={iconBackground}
          backgroundColor={backgroundColor}>
            <Main color={fontColor}>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
            </Main>
        </HowWorksBoxCampaign>
    )
}

export {HowWorksCampaign};
