import React from "react";
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import {Main, BoxContainer, BoxIcon} from './styles';

interface HowWorksBoxCampaignProps {
    iconData: IconProp;
    iconColor: string;
    iconBackground: string;
    backgroundColor: string;
    children: React.ReactNode;
}

const HowWorksBoxCampaign: React.FC<HowWorksBoxCampaignProps> =
  ({iconData, iconColor, iconBackground, backgroundColor, children}: HowWorksBoxCampaignProps) => {
    return (
        <Main>
            <BoxContainer backgroundColor={backgroundColor}>
                <BoxIcon backgroundColor={iconBackground}>
                    <I 
                        icon={iconData}
                        style={{ height: '2.25rem'}}
                        color={iconColor}/>
                </BoxIcon>
                {children}
            </BoxContainer>
        </Main>
    )
}

export {HowWorksBoxCampaign};