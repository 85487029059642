import React from 'react';

import { Main, CardHead, CardMedia, CardContent, Title, Description } from './styles';

import Placeholder640x410 from '@img/placeholder-640x410.png';

interface CardProps {
    image: string;
    title: string;
    description: string;
    children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ image, title, description, children }: CardProps) => {

    return (
        <Main>
            <CardHead>
                <CardMedia>
                    <div className="WrapAnchorPicture">
                        <div className="AnchorPicture">
                            <div className="WrapPicture">
                                <picture>
                                    <img
                                        src={image}
                                        alt={title}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = Placeholder640x410;
                                        }}
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </CardMedia>
            </CardHead>
            <CardContent>
                <Title>{title}</Title>
                <Description>
                    {description}
                </Description>
                {children}
            </CardContent>
        </Main>
    )
}

export { Card };