import styled from 'styled-components';

import { theme } from '@theme';

const { typography, breakpoints } = theme;

interface HeadlineProps {
    color: string;
}

export const Main = styled.h1<HeadlineProps>`
  font-size: 2.25rem;
  font-weight: ${typography.fontWeightBold};
  text-align: center;
  color: ${props => props.color};
  margin-bottom: 1.5625rem;
  
  ${breakpoints.down('md', `
    font-size: 1.625rem;
  `)}
`;
