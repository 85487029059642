import React from "react";
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {Main, Button, ArrowDiv, Text} from './styles';
import { Link } from "react-router-dom";

interface ParticipateButtonProps {
    link: string;
    backgroundColor: string;
    color: string;
}

const ParticipateButton: React.FC<ParticipateButtonProps> =
  ({backgroundColor, color, link}: ParticipateButtonProps) => {
    return (
        <Main>
            <Link to={link}>
                <Button backgroundColor={backgroundColor} color={color}>
                    <Text color={color}>
                        Participar
                    </Text>
                    <ArrowDiv color={color}>
                        <I 
                        icon={['far', 'chevron-right']}
                        style={{ height: '0.8125rem'}}
                        color={backgroundColor}/>
                    </ArrowDiv>
                </Button>
            </Link>
        </Main>
    )
}

export {ParticipateButton};