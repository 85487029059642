import styled from "styled-components";
import {theme} from '@theme';

interface ButtonProps {
    backgroundColor?: string;
    color: string;
}

export const Main = styled.div`
    padding-top: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    & > a {
        &:active {
            text-decoration: none;
        }
    }

`;

export const Button = styled.button<ButtonProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    padding: 4px;
    border: none;
    border-radius: ${theme.border.default};
`;

export const ArrowDiv = styled.div<ButtonProps>`
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.color};;
    border-radius: ${theme.border.default};
`;

export const Text = styled.span<ButtonProps>`
    color: ${(props) => props.color};
    font-size: 14px;
    padding: 0 26px;
`;