import styled from 'styled-components';
import {theme} from '@theme';

interface MainProps {
    borderColor: string;
}

interface HeaderProps {
    backgroundColor: string;
    color: string;
}

export const Main = styled.div<MainProps>`
    border-radius: ${theme.border.default};
    border: 1px solid ${props => props.borderColor};
    width: 273px;
`;

export const HeaderBox = styled.label<HeaderProps>`
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 78px;
    font-weight: ${theme.typography.fontWeightBold};
    font-size: 52px;
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};;
`;