import React, { createContext, useContext } from "react";

// import { path } from '../route-paths';

// import { hotsiteTheme, campaignTheme } from '@data/theme';

interface UseThemeContextData {
    theme: any;
    apply: (pathname: any) => any;
}

interface UseThemeProviderProps {
    children: React.ReactNode;
}

const ThemeContext = createContext<UseThemeContextData>({} as UseThemeContextData);

export function UseThemeProvider({ children }: UseThemeProviderProps): JSX.Element {

    const [theme, setTheme] = React.useState<any>({});

    const apply = (mainTheme: any) => setTheme(mainTheme)

    return (
        <ThemeContext.Provider
            value={{ theme, apply }}>
            {children}
        </ThemeContext.Provider>
    );
}

export function useThemeContext(): UseThemeContextData {
    const context = useContext(ThemeContext);
    return context;
}