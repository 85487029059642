import styled from "styled-components";

import {theme} from '@theme';

interface TextProps {
    color: string;
}

export const Main = styled.div<TextProps>`
    padding: 27px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${props => props.color};
`;

export const Title = styled.h1`
    font-size: 20px;
    padding-bottom: 14px;
`;

export const Subtitle = styled.p`
    font-size: 14px;
    line-height: 1.5;
    font-weight: ${theme.typography.fontWeightRegular};
`;