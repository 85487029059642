import React from "react";
import { Main, NavLink } from './styles';

interface NavItemProps {
    title: string;
    link: string;
    isActive: boolean;
    colorLink: string;
    activeColorLink: string;
    onFooter?: boolean;
    onClick?: () => void;
    action?: () => void;
}

const NavItem: React.FC<NavItemProps> = (
    { title, link, colorLink, activeColorLink, isActive, onClick, action, onFooter }: NavItemProps
) => {
    return (
        <Main onClick={onClick} className={onFooter ? 'footer' : ''}>
            <a href={link} style={{ textDecoration: 'none' }} onClick={action}>
                <NavLink
                    activeColorLink={activeColorLink}
                    colorLink={colorLink}
                    className={isActive ? onFooter ? 'active footer' : 'active' : onFooter ? 'footer' : ''}
                >
                    {title}
                </NavLink>
            </a>
        </Main>
    )
}

export { NavItem };