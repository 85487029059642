import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { settings, settingsSlider } from './configs';

import {
  Section,
  Container,
  Accordion,
  AccordionSummary,
  Headline,
  Card,
  Slider,
  ContactForm,
  ButtonIcon,
  Banner,
  Grid,
  HowWorksCampaign
} from '@design-system';
import { theme } from '@theme';

import { campaignTheme, faq } from '@data/theme';

import {
  MainSlider,
  SectionContact,
  FeaturedBox,
  ColumnBox,
  BoxTitle,
  BoxText,
  BoxSection,
} from './styles';
import { Footer } from '@components';

interface CampaignProps {
  theming: any;
}


const Campaign: React.FC<CampaignProps> = ({ theming }: CampaignProps) => {
  const { color } = theme;

  const settingsBannersSlider = {
    ...settingsSlider,
    nextArrow: <button><I icon={['far', 'chevron-right']} size="3x" /></button>,
    prevArrow: <button><I icon={['far', 'chevron-left']} size="3x" /></button>,
  };

  const sliderSettings = {
    ...settings,
    nextArrow: <button><I icon={['far', 'chevron-right']} size="2x" color={color.purple[900]} /></button>,
    prevArrow: <button><I icon={['far', 'chevron-left']} size="2x" color={color.purple[900]} /></button>,
  }

  const [isOpen, setIsOpen] = React.useState<number>(0);
  const [isOpenChildren, setIsOpenChildren] = React.useState<number>(0);


  const toggleOpen = (id: number) => () => {
    setIsOpen((isOpen: number) => isOpen === id ? 0 : id);
    setIsOpenChildren(prev => prev = 0);
  };

  const toggleOpenChildren = (id: number) => () => setIsOpenChildren(
    (isOpenChildren: number) => isOpenChildren === id ? 0 : id,
  );

  return (
    <React.Fragment>

      <>
        <Slider className='main-slider' {...settingsBannersSlider}>
          {campaignTheme.sliderData.map(({ src, srcMobile, link }) => (
            <Banner src={src} srcMobile={srcMobile} link={link} />
          ))}
        </Slider>
        <div id='como-participar' />
      </>

      <Section>
        <Container>
          <Headline color={campaignTheme.colorHeadliners}>Como participar</Headline>
          <Grid container>
            {campaignTheme.howWorksBoxData.map(({ title, subtitle, iconData }, i) => {
              return (
                <Grid item lg={4} md={12}>
                  <HowWorksCampaign
                    iconData={iconData as IconProp}
                    iconColor={campaignTheme.howWorksBoxStyles.iconColor}
                    iconBackground={campaignTheme.howWorksBoxStyles.iconBackground}
                    backgroundColor={campaignTheme.howWorksBoxStyles.backgroundColor}
                    fontColor={campaignTheme.howWorksBoxStyles.fontColor}
                    title={title}
                    subtitle={subtitle} />
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </Section>

      <BoxSection>
        <Section>
          <Container>
            <FeaturedBox className='left mr-b'>
              <ColumnBox className='column info'>
                <BoxTitle>{campaignTheme.boxSection1.title}</BoxTitle>
                <BoxText>{campaignTheme.boxSection1.text}</BoxText>
                <ButtonIcon
                  link={campaignTheme.boxSection1.button.link}
                  text={campaignTheme.boxSection1.button.text}
                  withBorder={campaignTheme.boxSection1.button.withBorder}
                  backgroundColor={campaignTheme.boxSection1.button.backgroundColor}
                  borderColor={campaignTheme.boxSection1.button.borderColor}
                  fontColor={campaignTheme.boxSection1.button.fontColor}
                  iconSrc={<I icon={['far', 'long-arrow-right']} size="lg" color={campaignTheme.boxSection1.button.colorIcon} />} />
              </ColumnBox>
              <ColumnBox className='column image'>
                <div className="WrapAnchorPicture">
                  <div className="AnchorPicture">
                    <div className="WrapPicture">
                      <picture>
                        <img src={campaignTheme.boxSection1.image} alt="Campanhas" />
                      </picture>
                    </div>
                  </div>
                </div>
              </ColumnBox>
            </FeaturedBox>

            <FeaturedBox className='right'>
              <ColumnBox className='column info'>
                <BoxTitle>{campaignTheme.boxSection2.title}</BoxTitle>
                <BoxText>{campaignTheme.boxSection2.text}</BoxText>
                <ButtonIcon
                  link={campaignTheme.boxSection2.button.link}
                  text={campaignTheme.boxSection2.button.text}
                  withBorder={campaignTheme.boxSection2.button.withBorder}
                  backgroundColor={campaignTheme.boxSection2.button.backgroundColor}
                  borderColor={campaignTheme.boxSection2.button.borderColor}
                  fontColor={campaignTheme.boxSection2.button.fontColor}
                  iconSrc={<I icon={['far', 'long-arrow-right']} size="lg" color={campaignTheme.boxSection2.button.colorIcon} />} />
              </ColumnBox>
              <ColumnBox className='column image'>

                <div className="WrapAnchorPicture">
                  <div className="AnchorPicture">
                    <div className="WrapPicture">
                      <picture>
                        <img src={campaignTheme.boxSection2.image} alt="Campanhas" />
                      </picture>
                    </div>
                  </div>
                </div>
              </ColumnBox>
            </FeaturedBox>
          </Container>
        </Section>
      </BoxSection>

      <Section sectionColor={campaignTheme.sectionPartnersColor}>
        <Container>
          <Headline color={campaignTheme.colorHeadliners}>Conheça nossos parceiros</Headline>
          <MainSlider>
            <Slider {...sliderSettings} className='campaign-slider'>
              {campaignTheme.genericSlider.map(({ title, description, image }, i) => (
                <Card
                  key={`${i + 1}`}
                  image={image}
                  title={title}
                  description={description}
                />
              ))}
            </Slider>
          </MainSlider>
        </Container>
        <div id='duvidas-frequentes' />
      </Section>

      <Section>
        <Container>
          <Headline color={campaignTheme.faq.title.color}>
            {campaignTheme.faq.title.text}
          </Headline>
          {faq.map(({ id, title, questions }, i) => {

            const idx = i + 1;

            return (

              <Accordion
                key={`${id}`}
                title={title}
                color={campaignTheme.faq.accordion.iconColor}
                onClick={toggleOpen(idx)}
                isActive={isOpen === idx}
              >
                {questions.map(({ id, title, text }, i) => {
                  const index = i + 1;
                  return (
                    <AccordionSummary
                      key={`${id}`}
                      count={index}
                      title={title}
                      color={campaignTheme.faq.accordion.iconColor}
                      onClick={toggleOpenChildren(index)}
                      isActive={isOpenChildren === index}>
                      {text}
                    </AccordionSummary>
                  )
                })}
              </Accordion>
            )
          })}

        </Container>
      </Section>

      <SectionContact sectionColor={campaignTheme.contact.backgroundColorButton} id='fale-conosco'>

        <Section sectionColor='transparent'>
          <Container>
            <ContactForm
              backgroundColorButton={campaignTheme.contact.backgroundColorButton}
              fontColorButton={campaignTheme.contact.fontColorButton}
            />
          </Container>
        </Section>
      </SectionContact>

      <Footer
        logo={theming.footer.logo}
        backgroundColor={theming.footer.backgroundColor}
        fontColor={theming.footer.fontColor}
        navData={theming.footer.nav}
      />
    </React.Fragment >
  );
}

export { Campaign };