import { BrowserRouter } from 'react-router-dom';
import Router from './Routes';

import { UseThemeProvider } from '@hooks';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

  return (
    <UseThemeProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </UseThemeProvider>
  );
}

export default App;
